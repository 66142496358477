import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row, Collapse } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { CommonHelper, DescriptionGpt } from '../../../Base/Common/CommonUIComponents';
import { DescriptionEditorText } from '../../../Base/Common/SupportedMultiLang';
import { getAPI, postAPI } from '../../../Base/API';
import { handleNotification } from '../../../Base/Notification';

export class PolicyDescriptions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            lang: 'en-GB',
            generateDescriptionCollapse: false,
            policies: {
                descriptions: []
            }
        };
    }

    componentDidMount() {
        const { url } = this.props;

        this.getDescriptions(url);
    }

    componentWillReceiveProps(nextProps) {
        const { url } = this.props;

        if (nextProps.url !== url) {
            this.getDescriptions(nextProps.url);
        }
    }

    getDescriptions = (url) => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.descriptions) {
                data.descriptions.map(description => {
                    const blocksFromHtml = htmlToDraft(description.description);
                    const { contentBlocks, entityMap } = blocksFromHtml;
                    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                    description.editorState = EditorState.createWithContent(contentState);
                });
                this.setState({ policies: data, block: false, error: errorMessage });
            }
            else {
                this.setState({ block: false, error: errorMessage });
            }
        }, url);
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const { url } = this.props;
        const { policies } = this.state;
        
        const descriptions = policies.descriptions.map(description => {
            const desc = description.editorState && description.editorState.getCurrentContent() ? draftToHtml(convertToRaw(description.editorState.getCurrentContent())) : '';
            const newDescription = {
                culture: description.culture,
                descriptionId: description.descriptionId,
                description: (desc && desc.toLocaleLowerCase() !== "<p></p>" && desc.toLocaleLowerCase() !== "\n<p></p>" && desc.toLocaleLowerCase() !== "<p></p>\n") ? desc : null,
                type: description.type ? description.type : 'Description'
            };
            return newDescription;
        });

        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data && data.descriptions) {
                handleNotification(data, <FormattedMessage id="ComplexDescription.SuccessMessageSave" />, <FormattedMessage id="General.Success" />);

                data.descriptions.map(description => {
                    const blocksFromHtml = htmlToDraft(description.description);
                    const { contentBlocks, entityMap } = blocksFromHtml;
                    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                    description.editorState = EditorState.createWithContent(contentState);
                });
                this.setState({ block: false, error: errorMessage, policies: data });
            }
            else {
                this.setState({ block: false, error: errorMessage });
            }

        }, `${url}`, JSON.stringify(descriptions));
    }

    onEditorStateChange = (newEditorState, lang) => {
        const policies = { ...this.state.policies };
        let text = policies.descriptions && policies.descriptions.find(el => el.culture === lang);

        if (text) {
            text.editorState = newEditorState;
        }
        else {
            let newItem = { ...this.state.emptyItem };
            newItem.culture = lang;
            newItem.editorState = newEditorState;
            policies.descriptions.push(newItem);
        }

        this.setState({ policies });
    };

    changeLanguage = (lang) => {
        this.setState({ lang });
    }

    toggleConfig = () => {
        this.setState({ generateDescriptionCollapse: !this.state.generateDescriptionCollapse });
    }

    render() {
        const { helperText, helperTextId } = this.props;
        const { policies } = this.state;

        const catDescToolbar = {
            options: ['inline', 'list', 'textAlign', 'link', 'emoji', 'remove', 'history'],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
        };

        return (
            <div>
                <Card className="small shadow  border-0">
                    <CardHeader className="bg-white border-bottom-1" >
                        <Row>
                            <Col>
                                <i className="fas fa-file-alt mr-1"></i>
                                <strong>
                                    <FormattedMessage id="RoomCategorySetupDetails.Policies" />
                                </strong>
                            </Col>
                            <Col className="text-right">
                                <Button color=" btn-host btn-sm mr-2" onClick={this.handleSubmit} >
                                    <i className="fas fa-save" />
                                </Button>

                                <CommonHelper help={helperText} id={helperTextId} />
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col className="col-12">
                                <DescriptionEditorText
                                    data={{
                                        texts: policies.descriptions,
                                        onEditorStateChange: this.onEditorStateChange
                                    }}
                                    customToolbar={catDescToolbar}
                                    changeLanguage={this.changeLanguage}
                                    hasGpt={true}
                                    generateDescriptionCollapse={this.state.generateDescriptionCollapse}
                                    toggleGptConfig={this.toggleConfig}
                                />
                                <Collapse className='w-100' isOpen={this.state.generateDescriptionCollapse}>
                                    <DescriptionGpt
                                        currentLang={this.state.lang}
                                        elementDescription={policies ?? {}}
                                        onEditorStateChange={this.onEditorStateChange}
                                        intl={this.props.intl}
                                        page={this.props.policyType === 'RoomCategory' ? 'RoomPolicies' : 'RatePolicies'}
                                    />
                                </Collapse>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        );
    }
}